import { render, staticRenderFns } from "./AccountSettingTfa.vue?vue&type=template&id=44b1038a&%3Aif=organisationOptions%20%26%26%20accountData&"
import script from "./AccountSettingTfa.vue?vue&type=script&lang=js&"
export * from "./AccountSettingTfa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports